"use client";
import React, { useState } from "react";
import AIPopupPC from "./AIPopupPc";
import "./AIPopup.css";
import { useAI } from "../contexts/AIContext";

export default function AIPopup() {
  const { open, setOpen, sendMessage,messageFlow, setMessageFlow } = useAI();

  return (
    <div
      className="aipopupcomponent-default-ai-felulet-gomb-chat-wrap"
      style={{
        position: "fixed",
        zIndex: 1000000000,
        pointerEvents: open ? "all" : "none",
      }}
    >
      <div
        style={{
          opacity: open ? 0 : 1,
          transition: "opacity ease 0.5s",
          pointerEvents: !open ? "all" : "none",
        }}
        className="aipopupcomponent-default-felulet-btn"
        onClick={() => setOpen(true)}
      >
        <img src="aiimages/Vector.svg" loading="lazy" alt="" className="aipopupcomponent-default-ai-image" />
      </div>

      {/*<AIPopupPhone isOpenState={[open, setOpen]} />*/}
      <AIPopupPC isOpenState={[open, setOpen]} messageFlow={messageFlow} onMessage={sendMessage} bgColors={["darkblue","#3898ec"]}/>
    </div>
  );
}
