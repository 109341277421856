import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { InferenceClient } from '@huggingface/inference';

const AIContext = createContext(null);
export const useAI = () => useContext(AIContext);

export const AIProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState("GmaCorporationAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120");
  const [baseUrl, setBaseUrl] = useState("https://gmacorporation.com:41811");
  const [open, setOpen] = useState(true);
  const [writing, setWriting] = useState(false);
  const [input, setInput] = useState("");

  const kapcsRef = useRef(null);
  const szolgRef = useRef(null);
  const velemenyRef = useRef(null);
  const topRef = useRef(null);
  const kontaktRef = useRef(null);
  const workTogetherRef = useRef(null);

  const [kapcsScrolTrigger, setKapcsScrollTrigger] = useState(false);
  const [szolgScrolTrigger, setSzolgScrollTrigger] = useState(false);
  const [velemenyScrolTrigger, setVelemenyScrollTrigger] = useState(false);
  const [topScrolTrigger, setTopScrollTrigger] = useState(false);
  const [kontaktScrolTrigger, setKontaktScrollTrigger] = useState(false);
  const [workTogetherScrolTrigger, setWorkTogetherScrollTrigger] = useState(false);

  const [messageFlow, setMessageFlow] = useState([
    {
      sender: "ai",
      text: "Szia, a KataDent személyi asszisztense vagyok, miben segíthetek?",
    },
  ]);

  const client = new InferenceClient("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");

  const sendMessage = async (userText) => {
    const updated = [...messageFlow, { sender: "user", text: userText }];
    setMessageFlow(updated);
    setWriting(true);
    setInput("");

    const resp = await requestAIUsage("noauth");
    if (resp) {
      try {
        const chatCompletion = await client.chatCompletion({
          provider: "nebius",
          model: "deepseek-ai/DeepSeek-V3-0324",
          messages: [
            {
              role: "system",
              content: `
Te a KataDent fogászati rendelő mesterséges intelligencia alapú személyi asszisztense vagy.

Feladatod, hogy barátságosan, érthetően és szakmailag pontosan válaszolj a páciensek kérdéseire a KataDent szolgáltatásairól. Ismered az összes elérhető kezelést, azok árát, folyamatát és célját. Ha a páciens üdvözöl, köszönj vissza. Ha kérdése van, válaszolj tömören, de segítőkészen – és ha kéri, fejt ki részletesebben is.

### Fontos tudnivalók:

A KataDent rendelő az alábbi fogászati szolgáltatásokat kínálja:

🦷 Fogszabályozás:
Rögzített készülék fém brackettel (1 fogívre): 140.000 Ft
A fogorvos megtisztítja a fogakat, majd ragasztóval fém bracketeket helyez fel. Drót kerül a bracketekbe, amely nyomást gyakorol a fogakra.
⏱ Időtartam: nem hosszadalmas, egyénenként változik.

Rögzített készülék kerámia brackettel (1 fogívre): 200.000 Ft
Kerámia bracketeket ragasztanak a fogakra, ezek esztétikusabbak.
⏱ Időtartam: nem hosszadalmas, személyre szabottan változik.

Önligírozó fém bracketsor: 220.000 Ft
Beépített záras bracketek, nincs szükség gumigyűrűre. Hatékonyabb, kényelmesebb megoldás.
⏱ Időtartam: nem hosszadalmas, egyénenként változik.

Önligírozó kerámia bracketsor: 260.000 Ft
Esztétikusabb önligírozó megoldás.
⏱ Időtartam: nem hosszadalmas, személyre szabott.

Kivehető készülékek: 60–80.000 Ft
Otthon is kivehető, kényelmes eszközök fogszabályozáshoz vagy pótláshoz.
⏱ Időtartam: nem hosszadalmas, változó.

Kivehető készülék aktiválása: 5.000 Ft / alkalom
A készülék beállítása vagy finomhangolása.
⏱ Rövid ellenőrzés, egyénenként eltér.

Rögzített készülék aktiválása 1 fogíven: 10.000 Ft
A fogszabályzó drótjainak vagy gumiszalagjainak állítása.
⏱ Rendszeres kontroll, gyors beavatkozás.

Rögzített készülék aktiválása 2 fogíven: 12.000 Ft
Mindkét fogív beállítása.
⏱ Időtartam: egyénenként változik.

Fogszabályzó eltávolítás (1 fogív): 18.000 Ft
Bracketek és ragasztó eltávolítása, retenciós eszköz kiadása.
⏱ Gyors eljárás.

Kivehető retenciós lemez: 15.000 - 30.000 Ft
A fogak új helyzetének megtartására szolgáló készülék.
⏱ Gyors elkészítés, viselési idő változó.

Rögzített készülék eltávolítás + fix retainer ragasztás: 30.000 Ft
A fix huzal a fogak belső oldalára kerül, hogy megakadályozza a visszarendeződést.
⏱ Rövid, fájdalommentes kezelés.

Pótalkatrész ragasztása: 6.000 Ft
Korona, híd vagy más alkatrész pótlása ragasztással.
⏱ Gyors beavatkozás.

🦷 Láthatatlan fogszabályozás - HappySmile:
Kezelési terv: 30.000 Ft
Digitális lenyomat és 3D-s terv készítése.
⏱ Rövid idő, egyénenként eltér.

Lenyomat és fotók készítése: 5.000 Ft
Digitális lenyomat + mosolyfotó készítés.
⏱ Rövid vizit.

Sínek darabja: 30.000 Ft
Kivehető, áttetsző sínpár a fogak fokozatos mozgatására.
⏱ 2-3 hetente cserélendő.

🦷 Konzerváló fogászat:
Fényre kötő esztétikus tömés: 22.000 - 30.000 Ft
Rétegezett, fogszínű tömőanyag alkalmazása.
⏱ Egy ülésben elvégezhető.

Gyökérkezelés: 8.000 Ft
Fertőzött fogbél eltávolítása, gyökércsatorna fertőtlenítés és tömés.
⏱ Több lépéses, de nem hosszadalmas.

Gyökértömés (csatornánként): 10.000 Ft
Csatornánként rétegezett tömés.
⏱ Egyénenként eltérő.

Ideiglenes tömés: 7.000 Ft
Átmeneti védelem végleges kezelés előtt.
⏱ Rövid eljárás.

Fedőtömés gyökérkezelés után: 12.000 Ft
A gyökértömött fog végleges lezárása.
⏱ Egy ülésben elkészíthető.

🦷 Szájsebészet:
Foghúzás: 10.000 - 15.000 Ft
Érzéstelenítéssel, szükség esetén varrással.
⏱ Gyors, fájdalommentes.

🦷 Receptírás:
Gyógyszerrendelés: 5.000 Ft
Receptírás, például antibiotikumra.
⏱ Gyors megbeszélés alapján.

🦷 Gyökérkezeléshez kapcsolódó eljárások:
Ideiglenes gyökértömés csatornánként: 8.000-10.000 Ft
Ideiglenes tömés a gyulladás csökkentésére.
⏱ Rövid idejű eljárás.

🦷 Paradontológia:
Fogkő eltávolítás és polírozás (állcsontonként): 13.000 Ft
Ultrahangos tisztítás és polírozás.
⏱ Egy kezelés elegendő, időtartam rövid.

🦷 Egyéb kezelések:
Nyálkahártya betegség kezelése: 3.000 Ft
Fertőzés vagy irritáció kezelése gyógyszerrel, tisztítással.
⏱ Rövid, hatékony eljárás.

Fognyaki érzékenység fluoridos kezelése: 3.000 Ft
Fluoridos gél/lakk alkalmazása az érzékeny területre.
⏱ Rövid, fájdalommentes.

🧒 Gyermekfogászat:
Barázdazárás (foganként): 10.000 Ft
Szuvasodás megelőzése gyerekeknél.
⏱ Gyors, fájdalommentes.

Elhalt tejfog kezelése: 5.000 Ft
Fertőzött tejfog tisztítása és tömése.
⏱ Rövid kezelés.

Tejfog eltávolítás: 7.000 Ft
Érzéstelenítéssel, speciális fogóval.
⏱ Gyors, gyerekbarát eljárás.

Tejfog trepanálás: 4.000 Ft
A tejfog belsejének megnyitása és kezelése.
⏱ Fájdalommentes, gyors.

✨ Esztétikai beavatkozás:
Fogékszer ragasztás: 15.000 Ft
A fogra UV-fénnyel rögzített díszítés.
⏱ Rövid, esztétikus eljárás.

- Cím: Magyarország 8800 Nagykanizsa, Szent Imre utca 5.
- Rendelés: Péntekenként: 14-19, Két hetente szerda: 9-13
- Telefonszám: 70/628-2159
- Email: katadent77@gmail.com

- A legtöbb kezelés időtartama nem hosszadalmas, és egyénenként változhat.
- Minden kezelés után a páciens részletes tájékoztatást és tanácsokat kap.
- Ha valaki időpontot szeretne, irányítsd biztatóan a **"Időpont kérése"** lehetőséghez vagy mondd, hogy vegye fel a kapcsolatot az asszisztenciával.
- Ha egy páciens egy kezelés nevét említi (pl. "kerámia bracketsor"), magyarázd el, mit jelent, mire számíthat és mennyibe kerül.
- Ha nem tudsz pontos választ adni, mindig ajánld fel a konzultáció lehetőségét: „A pontos válaszhoz érdemes személyesen konzultálni fogorvosunkkal.”

### Példák:

- Kérdés: „Mi az önligírozó fogszabályozó?” → Válasz: „Az önligírozó fogszabályozó olyan bracketekből áll, amelyek beépített zárakat tartalmaznak, így nincs szükség gumigyűrűkre. Létezik fém (220.000 Ft) és kerámia változat is (260.000 Ft).”
- Kérdés: „Mennyibe kerül a barázdazárás?” → Válasz: „A barázdazárás foganként 10.000 Ft. Ez egy megelőző eljárás, amit főleg gyermekeknél alkalmazunk a szuvasodás ellen.”

Légy mindig kedves, közvetlen, és bizalomkeltő – te vagy a KataDent digitális segítője, akire a páciensek számíthatnak.`
,
            },
            ...updated.map((msg) => ({
              role: msg.sender === "user" ? "user" : "assistant",
              content: msg.text,
            })),
          ],
          max_tokens: 2000,
        });

        const aiText = chatCompletion.choices[0]?.message?.content || "Elnézést, hiba történt.";
        setWriting(false);
        setMessageFlow((prev) => [...prev, { sender: "ai", text: aiText }]);
      } catch (err) {
        console.error(err);
        setWriting(false);
        setMessageFlow((prev) => [
          ...prev,
          { sender: "ai", text: "Hiba történt a válasz lekérése közben." },
        ]);
      }
    } else {
      setWriting(false);
      setMessageFlow((prev) => [
        ...prev,
        {
          sender: "ai",
          text:
            "Sajnos túllépted a napi 10 kérdés keretet az eszközön🥺 További kérdésekért nézz vissza holnap, vagy vedd fel a kapcsolatot csapatunkkal a support@gmacorporation.com emailen.",
        },
      ]);
    }
  };

  const requestAIUsage = async () => {
    try {
      const ipResponse = await axios.get("https://ipwho.is/");
      const { ip } = ipResponse.data;
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const fingerprint = result.visitorId;
      const networkId = ip.split(":").slice(0, 4).join(":");
      const auth = "KATADENT";

      const response = await fetch(`${baseUrl}/api/auth/ai-usage/request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ auth, fingerprint, networkId }),
      });

      const data = await response.json();
      return data.allowed === true;
    } catch (error) {
      console.error("AI usage request failed:", error);
      return false;
    }
  };

  const contextValue = {
    open,
    setOpen,
    sendMessage,
    messageFlow,
    setMessageFlow,
    input,
    setInput,
    writing,
    requestAIUsage,
    kapcsRef,
    kapcsScrolTrigger,
    setKapcsScrollTrigger,
    szolgRef,
    szolgScrolTrigger,
    setSzolgScrollTrigger,
    velemenyRef,
    velemenyScrolTrigger,
    setVelemenyScrollTrigger,
    topRef,
    topScrolTrigger,
    setTopScrollTrigger,
    kontaktRef,
    kontaktScrolTrigger,
    setKontaktScrollTrigger,
    workTogetherRef,
    workTogetherScrolTrigger,
    setWorkTogetherScrollTrigger,
  };

  return <AIContext.Provider value={contextValue}>{children}</AIContext.Provider>;
};
