

import React, { useEffect, useState,useRef } from "react";
import { marked } from "marked";
import { ThreeDot } from 'react-loading-indicators';
import { User2, User2Icon, UserCircle, UserCog2, UserX2 } from "lucide-react";
import { useAI } from "../contexts/AIContext";

export default function AIPopupPC({isOpenState,messageFlow,onMessage,bgColors = [ "#303d3d", "#181819"]}) {
    const [open,setOpen] = isOpenState;
    const {input,setInput,writing} = useAI();
    const messagesEndRef = useRef(null);
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, [messageFlow, writing,input]);
    return (
        <>
    <div className="aipopupcomponent-default-rendes-chat-felulet-skipped-wrap" style={{zIndex:10000000000,display: open?"flex":"none",WebkitTransform: `translateX(${open?"0%":"100%"})`,MozTransform: `translateX(${open?"0%":"100%"})`,msTransform:`translateX(${open?"0%":"100%"})`,transform:`translateX(${open?"0%":"100%"})`,transition:"transform ease 0.5s",backgroundImage:`url('aiimages/Dots.png'), linear-gradient(146deg, ${bgColors[0]}, ${bgColors[1]})`}}>
      <div className="aipopupcomponent-default-gma-ai-logo-skip-txt-copy">
        <div className="aipopupcomponent-default-div-block-4-ai">
          <div className="aipopupcomponent-default-div-block-19">
          </div>
          <img src="aiimages/Vector.svg" loading="lazy" alt="" className="aipopupcomponent-default-image-2"/>
          <div className="aipopupcomponent-default-text-block-2">KataDent Ai</div>
        </div>
        <div className="aipopupcomponent-default-close-chat" onClick={()=>setOpen(false)}>
          <img src="aiimages/xicon.svg" loading="lazy" alt="" />
        </div>
      </div>
      <div className="aipopupcomponent-default-beszelgetes-wrap" style={{height:"80%",overflowY:"auto",width:"100%",paddingLeft:0,paddingRight:5,marginRight:0,scrollbarWidth:"thin"}}>
        {messageFlow.map((message, i) => (
        <>
            {
            message.sender === "ai" ?
            <>
            <div className="aipopupcomponent-default-ai-sent-message" style={{marginBottom:20}}>
                <div className="aipopupcomponent-default-div-block-11">
                    <img src="aiimages/Vector.svg" loading="lazy" alt="" className="aipopupcomponent-default-image-3"/>
                </div>
                <div className="aipopupcomponent-default-message-content-wrap">
                <p
                    className="aipopupcomponent-default-paragraph"
                    style={{
                        color: "black",
                        width: "100%",
                        wordBreak: "break-word",
                        fontSize: "16px",
                        lineHeight: "1.6",
                    }}
                    dangerouslySetInnerHTML={{ __html: marked.parse(message.text) }}
                    />
                    <div className="aipopupcomponent-default-div-block-14">
                    </div>
                </div>
            </div>
            </>
            :
            <>
            <div className="aipopupcomponent-default-div-block-12">
                <div className="aipopupcomponent-default-div-block-13" style={{display:"flex",justifyContent:"center",alignItems:"center"}}><User2/></div>
                <div className="aipopupcomponent-default-message-content-wrap">
                    <p className="aipopupcomponent-default-paragraph-2">
                    {message.text}
                    </p>
                </div>
            </div>
            </>
            }
        </>
        ))}
        {writing && <div className="aipopupcomponent-default-ai-sent-message aipopupcomponent-default-ai-theedot-holder" style={{marginBottom:20,width:"20%"}}>
            <div className="aipopupcomponent-default-ai-theedot"><ThreeDot variant="bounce" color="black" size="small" text={""} textColor="" /></div>
        </div>}
        <div ref={messagesEndRef} />
      </div>
      <div className="aipopupcomponent-default-div-block-7">
        <div className="aipopupcomponent-default-form-block w-form">
          <form onSubmit={(e) => {
    e.preventDefault(); // Megakadályozza az oldal újratöltését
    if (input.trim()) onMessage(input); // csak ha nem üres
  }} id="email-form" name="email-form" style={{WebkitTransform: `translateY(${open?"0%":"200%"})`,MozTransform: `translateY(${open?"0%":"200%"})`,msTransform:`translateY(${open?"0%":"200%"})`,transform:`translateY(${open?"0%":"200%"})`,transition:"transform ease 0.5s",}} className="aipopupcomponent-default-form">
            <div className="aipopupcomponent-default-div-block-9">
              <input
                className="aipopupcomponent-default-text-field w-input"
                maxLength={256}
                name="field-2"
                data-name="Field 2"
                placeholder="Írd ide a kérdéséed..."
                type="text"
                id="field-2"
                value={input}
                onChange={(e)=>setInput(e.target.value)}
                required
              />
            </div>
            <div className="aipopupcomponent-default-send-btn" onClick={()=>onMessage(input)}>
              <div className="aipopupcomponent-default-div-block-20">
                <img src="aiimages/sendv2.svg" loading="lazy" alt="" className="aipopupcomponent-default-send-img"/>
                <img src="aiimages/nyilv3.svg" loading="lazy" style={{ opacity: open?0:1,transition:"opacity ease 0.5s" }} alt="" className="aipopupcomponent-default-ai-image-5"/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
        </>
    );
}